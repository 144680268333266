body {
    margin: 0px;
    
}
.container{
    background-color: #9fa1a6;
    
    width: 100%;
    max-width:100%;

}
.filterPos{
    
    display: flex;
    align-items: left;
    position: absolute;
    top: 1%;
    left:10%;

}

.col1{
    display: flex;
    flex-flow: column;
    min-width:  33%;
}
.MuiGrid-root, .MuiBox-root, .MuiTypography-root {
  
    height: 100%;
}
.MuiAppBar-root {

    height: max-content;
}

.MuiTab-wrapper {

    margin: 10px 5px 5px 5px!important;
}
.MuiTabs-fixed{
    height: fit-content!important;
    height: fit-content!important;
}
.MuiTabs-root {
    
    min-height: 5px!important;
}
.MuiBox-root-6 {
    padding: 5px!important;
}

.MuiAppBar-colorPrimary {
    background-color: #2f343d!important;
}

.makeStyles-root-7 {
    width: 100%;
    max-width: 100%;
    background-color: #434850!important;
}

#channels .MuiAppBar-colorPrimary {
    background-color: #2f343d!important;
    color:white;
}

#channels .MuiTab-textColorInherit.Mui-selected {
    color:white;
    background-color: #434850!important;
}

#channels .MuiTab-root {
    min-width: 25%!important;
    padding: 4px 4px!important;
    font-size: 0.875rem!important;
    /* height: fit-content!important; */
    min-height: 5px!important;
}
#channels .MuiGrid-root>.MuiButtonBase-root>.MuiIconButton-label>.MuiSvgIcon-root {
    font-size: 1.5rem!important;
}

#channels .MuiSvgIcon-root {
    font-size: 2.75rem!important;
}
#channels .MuiTab-wrapper {
    font-size: 0.775rem!important;
}


.MuiTab-textColorInherit.Mui-selected {
    color:black;
    background-color: #434850!important;
}

.MuiTab-root {
    min-width: 30%!important;
    padding: 4px 4px!important;
    font-size: 0.775rem!important;
    /* height: fit-content!important; */
    min-height: 5px!important;
}


.headerAlert{
    background: #f44336;
    color: white;
}
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.containerHome {
    /* width: 100%; */
    height: 100vh;
    height: -moz-calc(100% - 10px);
    height: -webkit-calc(100% - 10px);
    height: calc(90vh - 10px);
    display: flex;
    flex-flow: nowrap column;
    /* overflow-y:scroll; */
}

.containerTablePanels {
    /* width: 100%; */
    /* height: 200px; */
    /*height: -moz-calc(100% - 10px);
    height: -webkit-calc(100% - 10px);
    height: calc(90vh - 10px);
    display: flex;
    flex-flow: nowrap column; */
    overflow-y:scroll;
}

.panel {    
    display: flex;
    flex-flow: column;
}

.header {
    min-height: 50px;
    height: 100%;
    background: white;
}
.footer {
    height: 100%;
    display:flex;
    flex-flow: column nowrap;
    align-items: stretch;
    text-align: center;
    justify-content: flex-start;
    overflow: hidden;
}
.footerArea{
    flex-grow: 1;
    display: flex;
    min-height: 100px;
    flex-flow: column;
    overflow-y: auto;
}
.footerAreaContent {
    min-height: 500px;
}
.footerBottomBar {
    color: white;
    padding: 10px
}
.body {
    flex-grow: 3;
    display: flex;
    flex-flow: row nowrap;
}

.sideBarResizeContainer {
    width: 30%;
}
.sidebar {
    min-width: 240px;
    width: 400px;
    width:100%;
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;
}


.withMargin {
    margin: 10px;
    box-sizing: border-box;
}

.content {
    flex-grow: 1;  
    background: white;
    min-width: 100px;
}

.customHandle {
    cursor: ew-resize;
    width: 10px;
    height: 100px;
    margin: 0px -5px;
    background: lightblue;
  
    text-align: center;
    z-index: 99999;
    overflow: hidden;
    display: flex;
    align-items: center ;
}

.customResizeBorder {
    cursor: ew-resize;
    width: 5px;
    background: gray;
    display: flex;
    z-index: 99999;
    align-items: center ;
    align-content: center ;
    justify-content: center;
    overflow: visible;

}